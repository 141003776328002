<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '80px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-row type="flex" align="top" :gutter="10">
              <a-col :flex="1">
                <a-form-item label="变化时间" name="startTime">
                  <a-date-picker v-model:value="search.data.startTime" :disabled-date="disabledDate" :allowClear="false" inputReadOnly placeholder="开始时间" style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>

              <a-col> <div style="margin-top: 5px">~</div> </a-col>

              <a-col :flex="1">
                <a-form-item label="" name="endTime">
                  <a-date-picker v-model:value="search.data.endTime" :disabled-date="disabledDate" :allowClear="false" inputReadOnly placeholder="结束时间" style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="游戏ID" name="imPlayerId">
              <a-input v-model:value.number="search.data.imPlayerId" placeholder="请输入游戏ID" allowClear> </a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="userId" name="userId">
              <a-input v-model:value.number="search.data.userId" placeholder="请输入userId" allowClear> </a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="变化类型" name="rechargeType">
              <a-select v-model:value="search.data.rechargeType" placeholder="请选择变化类型" allowClear>
                <a-select-option :value="item.value" v-for="item in CurrencyRechargeType" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </l-search-container>
      </a-form>
    </template>

    <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="index" :loading="loading">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'changeAmount'">
          <span v-show="record.changeAmount >= 0" class="l-font-success l-bold"> +{{ record.changeAmount }} </span>
          <span v-show="record.changeAmount < 0" class="l-font-danger l-bold"> {{ record.changeAmount }} </span>
        </template>

        <template v-if="column.dataIndex === 'rechargeType'">{{ getLabelByValue(CurrencyRechargeType, record.rechargeType) }}</template>
      </template>
    </l-table>
  </l-table-container>
</template>

<script lang="ts">
import http from "@/common/http";
import { getLabelByValue, CurrencyRechargeType } from "@/common/head";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { dateIsBetween, getPageOffset } from "@/common/utils";
import { Dayjs } from "dayjs";
import { PaginationProps } from "ant-design-vue/es/pagination";

const columns = [
  {
    title: "序号",
    width: 70,
    dataIndex: "index",
    fixed: "left",
  },

  {
    title: "玩家游戏ID",
    width: 100,
    dataIndex: "imPlayerId",
    ellipsis: true,
    fixed: "left",
  },

  {
    title: "userId",
    width: 80,
    dataIndex: "userId",
    ellipsis: true,
  },

  {
    title: "昵称",
    width: 100,
    dataIndex: "nickName",
    ellipsis: true,
  },

  {
    title: "变化类型",
    width: 180,
    dataIndex: "rechargeType",
    ellipsis: true,
  },

  {
    title: "变化前数量",
    width: 150,
    dataIndex: "beforeAmount",
    ellipsis: true,
  },

  {
    title: "变化后数量",
    width: 150,
    dataIndex: "afterAmount",
    ellipsis: true,
  },

  {
    title: "变更数量",
    width: 150,
    dataIndex: "changeAmount",
    ellipsis: true,
  },

  {
    title: "备注",
    width: 150,
    dataIndex: "remark",
    ellipsis: true,
  },

  {
    title: "变化时间",
    width: 180,
    dataIndex: "time",
  },

  {
    title: "操作",
    key: "action",
    dataIndex: "action",
    fixed: "right",
    width: 100,
    align: "center",
  },
];

export default defineComponent({
  components: {},
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        imPlayerId: undefined,
        userId: undefined,
        startTime: "",
        endTime: "",
        rechargeType: undefined,
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    //开始结束时间
    const validTimeRange = reactive({
      minDate: "",
      maxDate: "",
    });

    //获取不可选择的时间
    const disabledDate = (currentDate: Dayjs) => {
      return !dateIsBetween(validTimeRange.minDate, validTimeRange.maxDate, currentDate);
    };

    //首次进入，获取可选的日期范围
    const getTime = async () => {
      loading.value = true;
      const { data } = await http.get("us_bearns_use_log/time");
      validTimeRange.minDate = data.minDate;
      validTimeRange.maxDate = data.maxDate;
      search.data.startTime = data.maxDate;
      search.data.endTime = data.maxDate;

      onSearch();
    };

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_bearns_use_log", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      let offset = getPageOffset(pagination.current!, pagination.pageSize!);
      data.value = result.data.data.map((item: any) => {
        item.index = ++offset;
        return item;
      });

      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = validTimeRange.maxDate;
      search.data.endTime = validTimeRange.maxDate;
      onSearch();
    };

    onMounted(() => {
      getTime();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      CurrencyRechargeType,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      disabledDate,
      getLabelByValue,
    };
  },
});
</script>
